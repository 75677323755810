export const converDate = (data) => {
    return new Promise((resolve) => {
        var datas = [];
        data.map((iteam) => {
            jsonDecode(iteam).then((jsonData) => {
                datas[iteam.identifier] = jsonData;
            });
            return iteam
        })
        resolve(datas);
    });
}

const jsonDecode = (data) => {
    return new Promise((resolve) => {
        data.container = data.container ? JSON.parse(data.container) : null;
        data.text = data.text ? JSON.parse(data.text) : null;
        data.images = data.images ? JSON.parse(data.images).map((iteam) => {
            getMeta(iteam.src).then((metaData) => {
                iteam.src = metaData
            });
            return iteam
        }) : null;
        data.avatar = data.avatar ? JSON.parse(data.avatar).map((iteam) => {
            getMeta(iteam.src).then((metaData) => {
                iteam.src = metaData
            });
            return iteam
        })[0] : null;

        data.icon = data.icon ? JSON.parse(data.icon) : null;
        data.map = data.map ? JSON.parse(data.map) : null;
        data.buttons = data.buttons ? JSON.parse(data.buttons) : null;
        data.form = data.form ? JSON.parse(data.form) : null;

        var collections = [];
        data.collection.map((iteam) => {
            jsonDecode(iteam).then((jsonData) => {
                collections.push(jsonData);
            });
            return iteam
        })
        data.collection = collections
        resolve(data);
    });
}

const getMeta = (src) => {
    return new Promise((resolve) => {
        var img = new Image;
        img.src = src;
        img.onload = function () {
            resolve({
                publicURL: src,
                extension: src.split('.').pop(),
                childImageSharp: {
                    gatsbyImageData: {
                        height: this.naturalHeight,
                        width: this.naturalWidth,
                        layout: "constrained",
                        images: {
                            fallback: {
                                src: src
                            }
                        }
                    }
                }
            });
        };
    });
}
