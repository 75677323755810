import React, { useContext } from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@ui-components/ContentText'
import Reveal from '@ui-components/Reveal'
import Divider from '@ui-components/Divider'
import WithDefaultContent from '@ui-blocks/WithDefaultContent'
import ContentForm from '@ui-components/ContentForm'
import ContentImages from '@ui-components/ContentImages'
import { TabsContext } from '@ui-components/Tabs'
import Tabs from '@ui-components/Tabs'
import ListItem from '@ui-components/ListItem'

const HeroBlock09 = ({ content, reverse, locale }) => {
  const {
    activeTab: { index = 0 }
  } = useContext(TabsContext)
  const { identifier, text } = content
  const images = content?.collection[index]?.images || content.images
  const hasMedia = images

  return (
    <Container
      id={identifier}
      contentStyles={{
        maxWidth: hasMedia ? `narrow` : 500,
        p: 0
      }}
    >
      <Flex
        sx={{
          alignItems: `stretch`,
          flexDirection: [
            reverse ? `column-reverse` : `column`,
            reverse ? `row-reverse` : `row`
          ]
        }}
      >
        {hasMedia && (
          <Box
            sx={{
              flexBasis: [null, null, null, `50%`],
              display: [null, null, `flex`],
              flex: 1,
              minWidth: [null, null, null, 500],
              height: '50%',
              position: `relative`,
              borderRadius: reverse ? `right` : `left`,
              overflow: `hidden`,
              margin: '10px',
            }}
          >
            {images && (
              <ContentImages
                content={{ images }}
                imageFit='cover'
                reverse={reverse}
                sx={{ borderRadius: '10px', margin: '10px' }}
              />
            )}
          </Box>
        )}
        {Array.isArray(content.collection) && (
          <Box sx={{
            flex: 1, py: [3, 4], px: [3, 5],
            flexBasis: [null, null, null, `50%`],
          }}>
            <ContentText content={text} space={3} />
            <Tabs space={3} id={identifier}>
              {content.collection
                ?.filter(Boolean)
                ?.map(({ text, collection, form }, index) => (
                  <Reveal
                    key={`item-${index}`}
                    effect='fadeIn'
                    content={{ text }}
                  >
                    {text && (
                      <>
                        <Box sx={{ textAlign: `center` }}>
                          <ContentText content={text?.slice(1)} />
                        </Box>
                        <Divider space={3} />
                      </>
                    )}
                    {collection?.map((props, index) => (
                      <ListItem key={`item-${index}`} {...props} compact />
                    ))}
                    {form && (
                      <ContentForm
                        form={form}
                        id={`form.${identifier}.${form.multiStep ? 'multi' : index
                          }`}
                        locale={locale}
                      />
                    )}
                  </Reveal>
                ))}
            </Tabs>
          </Box>
        )}
      </Flex>
    </Container>
  )
}

export default WithDefaultContent(HeroBlock09)
